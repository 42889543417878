import { Instagram, Tiktok, X, Youtube } from './Svg';

export type IconType = 'instagram' | 'x' | 'twitter' | 'tiktok' | 'youtube';

interface SocialIconProps {
  type: IconType;
  className?: string;
  fill?: string;
}

export const SocialIcon = ({ type, className, fill }: SocialIconProps) => {
  const icons = {
    instagram: Instagram,
    x: X,
    twitter: X,
    tiktok: Tiktok,
    youtube: Youtube,
  };

  const Icon = icons[type.toLowerCase() as IconType];

  return <Icon className={className} fill={fill} />;
};
